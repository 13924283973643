import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { getManual, getManualVersions, getSiteId } from '../api/manual';
import { GlobalContext } from '../context/GlobalState';
import { formatIcon } from '../utils/addIcon';
import { setLangPage } from '../utils/setLangPage';
import createDataRequest from '../utils/createDataRequest';
//Components
import { Header } from '../components/Header/Header';
import { Slider } from '../components/Slider/Slider';
import { Video } from '../components/Video/Video';
import { Text } from '../components/Text/Text';
import { Button } from '../components/Button/Button';
import { Iframe } from '../components/Iframe/Iframe';
import { Dropdown } from '../components/Dropdown/Dropdown';
import { CookieBanner } from '../components/CookieBanner/CookieBanner';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const StyledContainer = styled.div``;

const StyledContentContainer = styled.div`
  max-width: 768px;
  margin: 0 auto;
  min-height: 100vh;
  padding-bottom: 50px;
  background-color: ${(props) => props.theme.page_background_color};
`;

export const Manual = () => {
  let { lang, manual : manualName } = useParams();

  const [isPreviewPage, setIsPreviewPage] = useState(false);
  const GlobalStyle = createGlobalStyle`
  body::-webkit-scrollbar {
    color: transparent;
    width: 0;
  }
  `;
  const history = useHistory();
  const currentLocation = window.location;
  const { globalState, dispatch } = useContext(GlobalContext);

  const { data, loaded } = globalState;
  const { manual } = loaded && data;

  const getMatomoSiteId = async (url) => {
    const response = await getSiteId(url);

    if ('data' in response) {    
      dispatch({
        type: 'GET_SITE_ID',
        payload: response?.data?.data?.idsite || '',
      }); 
    } else {  
      console.log('Error getting SiteId!');  
    }
 
  };


  const getDataManual = async (account, manualName, en, isPreview) => {
    dispatch({
      type: 'SAVE_URL_REQUEST',
      payload: { account, manualName, en, isPreview },
    });
    const response = await getManual(account, manualName, en, isPreview);

    if ('data' in response) {    
      dispatch({
        type: 'GET_MANUAL_DATA',
        payload: response?.data?.data,
      });
      dispatch({
        type: 'SAVE_PARENT_PAGE',
        payload: currentLocation.href,
      }); 
    } else {   
        dispatch({
          type: 'GET_MANUAL_ERROR',
          payload: response?.message,
        }); 
        history.push('/404');
    }
 
  };

  
  useEffect(() => {
    const isLocal = process.env.REACT_APP_LOCAL;
    
    const { account, lang, isPreview } = createDataRequest(
      currentLocation,
      isLocal
      );

    if (isPreview) {
      setIsPreviewPage(true);
    } else {
      setIsPreviewPage(false);
    }
    lang
      ? getDataManual(account, manualName, lang, isPreview)
      : getManualVersions(account, manualName); 
  }, [currentLocation, history.location.pathname]);

  useEffect(() => {
    formatIcon();
  }, []);

  useEffect(() => {
    if (data) { 
      const favicon = getFaviconEl(); // Accessing favicon element
      favicon.href = data.account.favicon;
 
      getMatomoSiteId(data?.site_url)
    }
  }, [data]);

  useEffect(() => {
    if (manual) {
      const { is_rtl: isRtl, lang_code: langCode } = manual;
      setLangPage(isRtl, langCode);
    }
  }, [manual]);

  function getFaviconEl() {
    return document.getElementById('favicon');
  }

  const contentList =
  loaded &&
  manual &&
  manual.blocks.map(({ type, ...props }, id) => { 
    const firstSlider = id === 0 && type === 'image';
    
    switch (type) {
      case 'video':
        return <Video key={id} data={props} />;
      case 'text':
        return <Text key={id} data={props} />;
      case 'link':
        return <Button key={id} data={props} />;
      case 'image':
        return <Slider key={id} data={props} firstSlider={firstSlider} />;
      case 'iframe':
        return <Iframe key={id} data={props} />;
      case 'dropdown':
        return <Dropdown key={id} data={props} />;
        default:
          return null;
    }
  });

  return loaded ? (
    <StyledContainer className={'main-content'}>
      {isPreviewPage && <GlobalStyle />}
      <Header />
      <StyledContentContainer className='page-background content-container'>
        {contentList}
      </StyledContentContainer>
      <CookieBanner data={data?.account?.privacy_policy_settings} />
    </StyledContainer>
  ) : (
    <div></div>
  );
};
