export default function(currentLocation, isLocal, forSubPage) { //TODO: find best solution for this method 
        const { host, pathname } = currentLocation;

        // TODO: LOCAL: step 1 set accountname
        const testLocal = "full-empty-hv";
        
        let account = isLocal ? testLocal.split(".")[0] : host.split(".")[0];
        const isPreview = pathname.split("/").includes("preview"); 
        
        let lang;
        const manuSplit = pathname.split("/").filter( (string) => string !== "");
        
        if(manuSplit[1]?.length === 2 && manuSplit?.length >= 2) {
            lang = manuSplit[1];
        } else {
            lang = false;
        }

        return !forSubPage ?  { account, lang, isPreview } : { account, isPreview }
} 


// If you see this code and it hasn't been deleted yet. 
// Don't judge, the shitty-code here only because we were going to transfer the whole project to next.js and rewrote it from the beginning!